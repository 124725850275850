import byteSize from 'byte-size';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import { showError } from '../../alerts';
import { DEFAULTS, ILogItem, promiseTimeout } from '../../common';
import { scrollContainerToBottom } from '../../scrolls';
import { IUploadResult, useUploadFileMutation } from '../services/documents';
import LogItem from './LogItem';

interface IUploaderState {
	length: number;
	current: number;
	started: number;
	currentFileInfo?: string;
	log: ILogItem[];
	lastUploadedHash: string;
}

interface IUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
	files: FileList;
	onUploadDone: (documentHash?: string) => void;
	realm: string;
	isDraft: boolean;
	autoClose?: boolean;
	/**
	 * Якщо передано documentId, то завантажений документ додається до набору, що вже існує
	 */
	documentId?: number;
	documentNo?: number;
}
const Uploader = ({
	files,
	onUploadDone,
	realm,
	title,
	documentId,
	documentNo,
	autoClose = false,
	isDraft,
}: IUploaderProps) => {
	const [show, setShow] = useState(true);
	const [state, setState] = useState<IUploaderState>({
		length: files.length,
		current: 0,
		started: new Date().valueOf(),
		log: [],
		lastUploadedHash: '',
	});
	const refLogContainer = useRef<HTMLDivElement>(null);
	const [processFile] = useUploadFileMutation();

	const handleClose = async () => {
		setShow(false);
		await promiseTimeout(500);
		onUploadDone(state.lastUploadedHash);
	};

	useEffect(() => {
		const uploadFiles = async (files: FileList) => {
			try {
				if (!files || files.length === 0) throw new Error('Файл не обрано');

				for (let index = 0; index < files.length; index++) {
					const file = files[index];
					const info = `${file.name} (${byteSize(file.size)})`;
					setState((state) => ({
						...state,
						currentFileInfo: `Завантажується ${info}`,
					}));
					const result = await uploadFile(file);
					if (terminated) return;

					setState((state) => ({
						...state,
						current: index + 1,
						currentFileInfo: `Завантажено ${info}`,
						lastUploadedHash: result.documentHash || '',
						log: [
							...state.log,
							{
								success: result.error === 0,
								fileName: file.name,
								message: result.error === 0 ? 'завантажено' : result.error,
								hitDate: new Date(),
							} as ILogItem,
						],
					}));
					scrollContainerToBottom(refLogContainer.current, 'smooth');
					// console.log(result);
					await promiseTimeout(process.env.NODE_ENV === 'production' ? 100 : 200);
				}
				// console.log('files Uploaded');
				setState((state) => ({
					...state,
					current: files.length,
					currentFileInfo: '',
				}));
				// await promiseTimeout(500);
			} catch (error) {
				if (!terminated) showError((error as Error).message);
			} finally {
				setTimeout(() => scrollContainerToBottom(refLogContainer.current, 'smooth'), 500);
			}
		};

		const uploadFile = async (file: File) => {
			// console.log(`Uploading ${file.name}...`);
			if (DEFAULTS.uploadFileMaxSize < file.size)
				return {
					error: `Розмір файлу ${byteSize(
						file.size
					)} перевищує максимальний визначений розмір для завантаження ${byteSize(DEFAULTS.uploadFileMaxSize)}.`,
					documentHash: '',
				};
			if (!(file.name.endsWith('.docx') || file.name.endsWith('.pdf') || file.name.endsWith('.doc')))
				return { error: 'Даний тип файлу не підтримується' };

			const formData = new FormData();
			/**
			 * Увага: тут порядок додавання полів має значення
			 * Поле з назвою має бути додане першим!
			 * Знайшов інше рішення. Див. в коді серверу.
			 */
			// formData.append('filename', encodeURIComponent(file.name));
			formData.append('document', file, file.name);
			formData.append('modified', file.lastModified.toString());
			if (documentId) formData.append('documentId', documentId.toString());
			if (documentNo) formData.append('documentNo', documentNo.toString());

			const result = await processFile({ data: formData, realm, draft: isDraft });

			if ((result as any).error)
				return { error: (result as any).error.data || JSON.stringify((result as any).error) } as IUploadResult;
			return (result as any).data as IUploadResult;
		};

		let terminated = false;
		setState({ length: files.length, current: 0, started: new Date().valueOf(), log: [], lastUploadedHash: '' });

		// Захист від повторного завантаження через використання StrictMode
		const timeout = setTimeout(() => {
			uploadFiles(files);
		}, 100);
		return () => {
			clearTimeout(timeout);
			terminated = true;
		};
	}, [files, realm]); // eslint-disable-line

	useEffect(() => {
		if (state.current !== state.length) return;
		if (autoClose && state.log.every(({ success }) => success)) setTimeout(handleClose, 500);
	}, [state]); // eslint-disable-line

	if (!state || state.length === 0) return null;

	const allDone = state.current === state.length;

	return (
		<Modal show={show} backdrop="static" keyboard={false} size="xl" centered onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Завантаження файлів в сегмент «{title}». Зачекайте...</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* {allDone ? (
					<h5 className="text-success">Завантаження завершене!</h5>
				) : (
					<> */}
				<ProgressBar
					animated={!allDone}
					now={(state.current / state.length) * 100}
					label={`${((state.current / state.length) * 100).toFixed(1)} %`}
				/>
				<div className="d-flex">
					<div className="text-center">
						{allDone ? <span className="text-success">Завантаження файлів завершене!</span> : state.currentFileInfo}
					</div>
					{!allDone && <div className="ms-auto">{getTimeLabel(state)}</div>}
				</div>
				{/* </> */}
				{/* )} */}
				<div className="my-1">Детальна інформація про завантаження:</div>
				<LogContainer className="p-1 bg-dark text-white" ref={refLogContainer}>
					{state.log.map((item) => (
						<LogItem key={item.fileName} item={item} />
					))}
				</LogContainer>
			</Modal.Body>
			<Modal.Footer>
				{allDone ? (
					<Button variant="primary" onClick={handleClose}>
						Закрити
					</Button>
				) : (
					<Button variant="secondary" onClick={handleClose} disabled={!isDraft}>
						Скасувати
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default Uploader;

const getTimeLabel = ({ started, length, current }: IUploaderState) => {
	const elapsed = (new Date().valueOf() - started) / 1000; // sec
	if (elapsed < 0.1) return '';
	const remaining = (length / current) * elapsed - elapsed;
	return `${Math.round(elapsed)} сек. / ${Math.round(remaining)} сек.`;
};

const LogContainer = styled.div`
	height: 50vh;
	overflow: auto;
	font-family: 'Courier New', monospace;
`;
